<!--
 * @Author: 刘逛 180768871@qq.com
 * @Date: 2025-01-10 14:51:23
 * @LastEditors: 刘逛 180768871@qq.com
 * @LastEditTime: 2025-01-26 08:42:10
 * @FilePath: /监管/jianguan/src/App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div v-if="!currRoute.meta.showCommon">
      <router-view/>
  </div>
  <div v-else>
    <el-container>
      <el-header>
        <NavComponents @update-active-index="setActiveIndex"/>
      </el-header>
      <el-container>
        <el-aside width="200px">
          <MenuComponents :active-index="activeIndex"/>
        </el-aside>
        <el-container>
          <el-main>
              <div class="breadcrumb mb-4">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item v-for="(item, index) in breadcrumbs" :key="index">{{ item.meta.title }}</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
              <div class="main">
                <router-view/>
              </div>
          </el-main>
          <el-footer>©安全生产责任保险多源数据分析平台</el-footer>
        </el-container>
      </el-container>
</el-container>

  </div>
  </div>
</template>

<script>
import MenuComponents from './components/MenuComponents.vue'
import NavComponents from './components/NavComponents.vue'
import router from './router';
export default {
  components: {
    MenuComponents,
    NavComponents
  },
  data() {
    return {
      currRoute:{},
      breadcrumbs: [],
      activeIndex: '1', // 当前激活的索引
    };
  },
  created() {
    this.generateBreadcrumbs();
  },
  mounted() {
    document.title = "安全生产责任保险多源数据分析平台";
  },
  watch: {
    '$route'() {
      this.generateBreadcrumbs();
    }
  },
  methods:{
    generateBreadcrumbs() {
        this.breadcrumbs = [];
        this.currRoute = this.$route
        const matchedRoutes = this.$route.matched;
        let mached = matchedRoutes.filter((item) => item.meta.title);
        // 第一个路径如果不是首页，需要在匹配路径前面拼接一个首页
        const first = mached[0] || [];
        //设置面包屑的数据
        this.breadcrumbs = mached;
      },
    setActiveIndex(index) {
      this.activeIndex = index; // 更新 activeIndex
    },
  }
}
</script>

<style lang="less">
  *{
    margin: 0;
    padding: 0;
  }
  .el-header, .el-footer {
    padding: 0 !important;
    background-color: #fff;
    color: #333;
    text-align: center;
    line-height: 60px;
  }

  .el-aside {
    background-color: #fff;
    color: #333;
    line-height: 200px;
    height: calc(100vh - 60px);
    .el-submenu.is-active .el-submenu__title {
        border-bottom-color: #1abc9c;
        color: #1abc9c;
    }
    .el-menu-item.is-active {
          color: #1abc9c;
          background: linear-gradient(91deg, #ffffff, #30bd9c5c);
          border-right: 3px solid;
      }
  }

  .el-main {
    padding: 0 !important;
    background-color: #f5f7f9;
    overflow-y: auto;
    height: calc(100vh - 120px);
    .breadcrumb{
      background-color: #f5f5f5;
      padding: 20px;
    }
    .main{

    }
  }

  body > .el-container {
    margin-bottom: 40px;
  }

  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }


  .el-drawer__body{ overflow: auto; }
  .el-drawer__container ::-webkit-scrollbar{ display: none; }
</style>
