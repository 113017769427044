<template>
  <div>
    
    <el-menu router class="el-menu-vertical-demo">
      
      <div v-show="activeIndex == 7">
        <el-submenu index="/workbench" v-has="13">
        <template slot="title">
          <i class="el-icon-bangzhu"></i>
          <span>工作台</span>
        </template>
        <el-menu-item index="/business_consulting" v-has="14"
          >业务咨询</el-menu-item
        >
        <el-menu-item index="/business_handling" v-has="15"
          >业务办理</el-menu-item
        >
        <el-menu-item index="/business_service_evaluation" v-has="16"
          >服务评价</el-menu-item
        >
      </el-submenu>
      </div>
      <div v-show="activeIndex == 6">
        <el-submenu index="/statistical_analysis" v-has="59">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>统计分析</span>
        </template>
        <el-menu-item index="/data_summary" v-has="60">数据汇总</el-menu-item>
        <el-menu-item index="/data_analysis" v-has="61">数据分析</el-menu-item>
        <el-menu-item index="/strategy_adjustment" v-has="62"
          >策略调整</el-menu-item
        >
        <el-menu-item index="/business_optimization" v-has="63"
          >业务优化</el-menu-item
        >
      </el-submenu>

      <el-submenu index="/system_management" v-has="76">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>客户教育</span>
        </template>
        <el-menu-item index="/education_curriculum" v-has="77"
          >教育课程</el-menu-item
        >
        <el-menu-item index="/online_q_a" v-has="78">在线问答</el-menu-item>
      </el-submenu>
      </div>


      <div v-show="activeIndex == 5">
        <el-submenu index="/multi_dimensional_statistical_analysis" v-has="64">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>多维度统计分析</span>
        </template>
        <el-menu-item index="/risk_assessment_model" v-has="65"
          >风险评估模型</el-menu-item
        >
        <el-menu-item index="/early_warning_system" v-has="66"
          >预警系统</el-menu-item
        >
        <el-menu-item index="/decision_support" v-has="67"
          >决策支持</el-menu-item
        >
        <el-menu-item index="/data_acquisition" v-has="68"
          >数据采集</el-menu-item
        >
        <el-menu-item index="/multidimensional_data_analysis" v-has="69"
          >数据分析</el-menu-item
        >
        <el-menu-item index="/report_generation" v-has="70"
          >报表生成</el-menu-item
        >
      </el-submenu>
      </div>
      
      <div v-show="activeIndex == 4">
        <el-submenu index="/policy_services" v-has="47">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>保单服务</span>
        </template>
        <el-menu-item index="/customer_service" v-has="48"
          >客户服务</el-menu-item
        >
        <el-menu-item index="/service_process_optimization" v-has="49"
          >服务流程优化</el-menu-item
        >
        <el-menu-item index="/application_acceptance" v-has="50"
          >申请受理</el-menu-item
        >
        <el-menu-item index="/risk_assessment" v-has="51"
          >风险评估</el-menu-item
        >
        <el-menu-item
          index="/determination_of_underwriting_conditions"
          v-has="52"
          >承保条件确定</el-menu-item
        >
        <el-menu-item index="/contract_signing" v-has="53"
          >合同签订</el-menu-item
        >
        <el-menu-item index="/order_management" v-has="54"
          >订单管理</el-menu-item
        >
      </el-submenu>

      <el-submenu index="/claims_services" v-has="55">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>理赔服务</span>
        </template>
        <el-menu-item index="/claims" v-has="56">理赔申请</el-menu-item>
        <el-menu-item index="/claims_review" v-has="57">索赔审核</el-menu-item>
        <el-menu-item index="/payment_of_compensation" v-has="58"
          >赔偿支付</el-menu-item
        >
      </el-submenu>

      <el-submenu index="/global_statistical_analysis" v-has="71">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>统计分析</span>
        </template>
        <el-menu-item index="/global_data_analysis" v-has="72"
          >数据分析</el-menu-item
        >
        <el-menu-item index="/risk_management_recommendations" v-has="73"
          >风险管理建议</el-menu-item
        >
        <el-menu-item index="/service_data_collection" v-has="74"
          >服务数据收集</el-menu-item
        >
        <el-menu-item index="/global_strategy_adjustment" v-has="75"
          >策略调整</el-menu-item
        >
      </el-submenu>
      </div>

      <div v-show="activeIndex == 3">
        <el-submenu index="/intermediary" v-has="23">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>中介管理</span>
        </template>
        <el-menu-item index="/intermediary_reg" v-has="24"
          >中介注册</el-menu-item
        >
        <el-menu-item index="/intermediary_audit" v-has="25"
          >中介审核</el-menu-item
        >
        <el-menu-item index="/intermediary_rating" v-has="26"
          >中介评级</el-menu-item
        >
        <el-menu-item index="/mediation_selection" v-has="27"
          >中介选择</el-menu-item
        >
        <el-menu-item index="/service_evaluation" v-has="29"
          >服务评价</el-menu-item
        >
        <el-menu-item index="/insurance_transaction_processing" v-has="28"
          >保险事务处理</el-menu-item
        >
      </el-submenu>

      </div>

      <div v-show="activeIndex == 2">
        <el-submenu index="/risk" v-has="17">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>风险管理</span>
        </template>
        <el-menu-item index="/risk_monitoring" v-has="18"
          >风险监测</el-menu-item
        >
        <el-menu-item index="/risk_assessment_report" v-has="19"
          >风险评估报告</el-menu-item
        >
        <el-menu-item index="/safety_improvements" v-has="20"
          >安全管理改进</el-menu-item
        >
        <el-menu-item index="/risk_control" v-has="21">风险控制</el-menu-item>
        <el-menu-item index="/risk_prevention" v-has="22"
          >风险预防</el-menu-item
        >
      </el-submenu>

      <el-submenu index="/compensation_and_schedule_management" v-has="43">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>赔付与进度管理</span>
        </template>
        <el-menu-item index="/claim" v-has="44">申请提交</el-menu-item>
        <el-menu-item index="/progress_query" v-has="45">进展查询</el-menu-item>
        <el-menu-item index="/result_of_compensation" v-has="46"
          >赔付结果</el-menu-item
        >
      </el-submenu>
      </div>

      <div v-show="activeIndex == 1">
        <el-submenu index="/data_presentation" v-has="1">
          <template slot="title">
            <i class="el-icon-bangzhu"></i>
            <span>数据展示</span>
          </template>
          <el-menu-item index="/instrument_cluster" v-has="2"
            >仪表盘</el-menu-item
          >
        </el-submenu>

        <el-submenu index="/institutions" v-has="36">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>机构管理</span>
        </template>
        <el-menu-item index="/institutions/institutions_reg" v-has="37"
          >机构注册</el-menu-item
        >
        <el-menu-item index="/institutions_audit" v-has="38"
          >机构审核</el-menu-item
        >
        <el-menu-item index="/institutions_maintenance" v-has="40"
          >机构信息维护</el-menu-item
        >
        <el-menu-item index="/institutions_violations" v-has="41"
          >违规处理</el-menu-item
        >
      </el-submenu>

      <el-submenu index="/enterprise" v-has="30">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>企业管理</span>
        </template>
        <el-menu-item index="/enterprise_reg" v-has="31">企业注册</el-menu-item>
        <el-menu-item index="/enterprise_insured" v-has="33"
          >参保管理</el-menu-item
        >
        <el-menu-item index="/production_safety_status_monitoring" v-has="34"
          >生产安全状态监控</el-menu-item
        >
        <el-menu-item index="/enterprise_risk_assessment" v-has="35"
          >风险评估</el-menu-item
        >
      </el-submenu>

      <el-submenu index="/interactive_operation" v-has="9">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>交互操作</span>
        </template>
        <el-menu-item index="/enterprise_historical_data" v-has="10"
          >企业历史信息</el-menu-item
        >
        <el-menu-item index="/enterprise_information_dynamic_update" v-has="11"
          >企业信息动态更新</el-menu-item
        >
      </el-submenu>

      <el-submenu index="/analysis_tools" v-has="4">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>分析工具</span>
        </template>
        <el-menu-item index="/trend_analysis" v-has="6">趋势分析</el-menu-item>
        <el-menu-item index="/comparative_analysis" v-has="7"
          >比较分析</el-menu-item
        >
        <el-menu-item index="/prediction_model" v-has="8"
          >预测模型</el-menu-item
        >
      </el-submenu>
      </div>
      

      

      

      

      

      

      

      

      
      

      <el-submenu index="/customer_education" v-has="79">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>系统管理</span>
        </template>
        <el-menu-item index="/account_management" v-has="80"
          >账户管理</el-menu-item
        >
        <el-menu-item index="/role_management" v-has="81"
          >角色管理</el-menu-item
        >
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  props: {
    activeIndex: {
      type: String,
      default: "1", // 默认值
    },
  },
};
</script>

<style></style>
