/*
 * @Author: 刘逛 180768871@qq.com
 * @Date: 2024-10-09 16:25:32
 * @LastEditors: 刘逛 180768871@qq.com
 * @LastEditTime: 2025-03-10 18:09:33
 * @FilePath: /admin/src/utils/haspermissions.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const jg_user_info = localStorage.getItem('jg_user_info') || '{}';


let role_permission = JSON.parse(jg_user_info).role_permission || [];
//console.log("role_permission_111",role_permission);
export default function (Vue) {
    Vue.directive('has', {
        inserted(el, binding) {
            const requiredPermission = parseInt(binding.value); // 获取当前按钮的权限
           
            checkPermission(el, requiredPermission);
        }
    });

    function checkPermission(el, requiredPermission) {
        console.log("requiredPermission",requiredPermission);
        const jg_user_info = localStorage.getItem('jg_user_info');
        const role_permission = JSON.parse(jg_user_info).role_permission || [];
        
        if (!role_permission.includes(requiredPermission) && JSON.parse(jg_user_info).is_admin !== 1) { // 判断当前用户的权限是否包含当前按钮的权限
            // console.log('没有权限');
            el.parentNode && el.parentNode.removeChild(el);
        }
    }

    // 监听权限信息的变化，实时更新前端权限状态
    window.addEventListener('storage', function(event) {
        if (event.key === 'jg_user_info') {
            const role_permission = JSON.parse(event.newValue).role_permission || [];
            //console.log("role_permission_333",role_permission);
            
            const buttons = document.querySelectorAll('[v-has]');
            buttons.forEach(button => {
                const requiredPermission = parseInt(button.getAttribute('v-has'));
                checkPermission(button, requiredPermission);
            });
        }
    });
}