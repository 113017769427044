<!--
 * @Author: 刘逛 180768871@qq.com
 * @Date: 2025-01-10 15:23:51
 * @LastEditors: 刘逛 180768871@qq.com
 * @LastEditTime: 2025-02-26 10:11:53
 * @FilePath: /监管/jianguan/src/components/NavComponents.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
  <div class="box">
    <div class="left">
      <div class="logo">
        <!-- <el-image style="height: 50px;" src="https://www.logomaker.com.cn/statics/images/logo2023.png?v=2"></el-image> -->
      </div>
    <div class="nav_box">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="1">监管一张图</el-menu-item>
        <el-menu-item index="2">风控服务</el-menu-item>
        <el-menu-item index="3">中介服务</el-menu-item>
        <el-menu-item index="4">统一统计分析</el-menu-item>
        <el-menu-item index="5">多维度统计分析</el-menu-item>
        <el-menu-item index="6">统计分析</el-menu-item>
        <el-menu-item index="7">工作台</el-menu-item>
      </el-menu>
    </div>
    </div>
    <div class="right mr-4">
        <div class="info_box">
          <div class="avatar">
            <el-avatar :size="40" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
          </div>
          <div class="name"> {{ userInfo.name }}</div>
        </div>

        <div class="logout" @click="loginout()">
          <i class="el-icon-switch-button"></i>
        </div>
    </div>
   
  </div>
</template>

<script>
import { mapGetters,mapActions } from 'vuex';

export default {
  data(){
    return {
      activeIndex:'1', // 默认激活的菜单项
      exitDialogVisible:false
    }
  },

  computed: {
    ...mapGetters('user', ['isLoggedIn', 'getUser']), // 映射 user 模块中的 getters

    userInfo() {
      console.log("this.getUser",this.getUser);
      if(this.getUser == null) {
        this.$router.push('/')
      }
      return this.getUser; // 使用getter获取用户名
    },
  },

  methods: {
    ...mapActions("user", ["logoutUser"]),
    loginout(){
      this.$confirm('确认要退出登录吗?', '警告', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.logoutUser()
          this.$router.push('/')
        }).catch(() => {
                  
        });
     

     
    },
    handleSelect(index) {
      this.activeIndex = index; // 更新当前激活的菜单项
      this.$emit('update-active-index', index); // 将激活的索引发送给父组件
    },
  }
};
</script>


<style lang="less">
.box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  .left{
      display: flex;
      flex-direction: row;
      .logo {
        width: 200px;
        height: 60px;
      }
  }
  .right{
    display: flex;
    flex-direction: row;
    align-content: center;
    .info_box{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 20px;
      .avatar{
        height: 40px;
        margin-right: 10px;
      }
    }
  }

}
</style>
